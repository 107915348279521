import Vue from "vue";

import { Dialog } from "vant";
import { Icon } from "vant";
import { List } from "vant";

import { Form } from "vant";
import { Field } from "vant";
import { Button } from "vant";
import { RadioGroup, Radio } from "vant";
import { Uploader } from "vant";
import { Checkbox, CheckboxGroup } from "vant";
import { Toast } from "vant";
// import { Dialog } from "vant";
import { Overlay } from "vant";
import { Loading } from "vant";

Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Toast);
// Vue.use(Dialog);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Button);
Vue.use(Icon);

Vue.use(Uploader);
Vue.use(List);

Vue.use(Form);
Vue.use(Field);

Vue.use(Radio);
Vue.use(RadioGroup);

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    confirm: false,
  },
  mutations: {
    updateConfirm(state, value) {
      state.confirm = value;
    },
  },
  actions: {},
  modules: {},
});

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "lib-flexible";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/plugins/Vant";
Vue.use(ElementUI);
Vue.config.productionTip = false;
// 防止多次点击
import preventReClick from "./utils/index.js";
Vue.use(preventReClick);

// 提交以后禁用按钮一段时间，防止重复提交
Vue.directive("noMoreClick", {
  inserted(el, binding) {
    el.addEventListener("click", (e) => {
      el.classList.add("is-disabled");
      el.disabled = true;
      setTimeout(() => {
        el.disabled = false;
        el.classList.remove("is-disabled");
      }, 3000); //我这里设置的是2000毫秒也就是2秒
    });
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // name: 'Home',
    // component: Home
    component: () => import("../views/home/home.vue"),
    // component: () => import("../views/report/index.vue"),
  },
  // 主页
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home/home.vue"),
  },
  // 二级页面 各种类型
  {
    path: "/report",
    name: "kind",
    component: () => import("../views/report/index.vue"),
  },
  // //三级类型分类详情页
  // {
  //   path: '/kindDetail',
  //   name: 'kindDetail',
  //   component: () => import('../views/kindDetail/index.vue')
  // },
  // //视频课程
  // {
  //   path: '/videoCourse',
  //   name: 'videoCourse',
  //   component: () => import('../views/videoCourse/index.vue')
  // },
  // //考试题库
  // {
  //   path: '/examDatabase',
  //   name: 'examDatabase',
  //   component: () => import('../views/examDatabase/index.vue')
  // },
  // //备考资料
  // {
  //   path: '/reference',
  //   name: 'reference',
  //   component: () => import('../views/reference/index.vue')
  // },
  // //考试资讯
  // {
  //   path: '/information',
  //   name: 'information',
  //   component: () => import('../views/information/index.vue')
  // },
  {
    path: "*", // * 表示匹配所有路径
    redirect: "/", // 这里意思就是当上述匹配的路径都不成立时，将其他任意路径替换为访问 /
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
//禁止输入域名进入表单页
router.beforeEach((to, from, next) => {
  console.log("to", to);
  if (to.path === "/report") {
    if (localStorage.getItem("confirm")) {
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
});

export default router;
